import { filter, first } from 'rxjs/operators';

function getPolicyManager() {
    return new Promise(resolve => {
        if (window.Rai?.PolicyManager) {
            resolve(window.Rai.PolicyManager);
        } else {
        const origObj = window.Rai ?? {};
        window.Rai = new Proxy(origObj, {
            set(target, prop, value) {
            const setted = Reflect.set(target, prop, value);
            if (prop === 'PolicyManager') {
                window.Rai = origObj;
                window.setTimeout(() => resolve(window.Rai.PolicyManager), 0);
            }
            return setted;
            }
        });
        }
    })
}

export async function getGDPRManager() {
    const policyManager = await getPolicyManager();
    return new Promise(resolve => {
        const sub = policyManager.events$.pipe(
        filter(ev => ev.type === policyManager.events.POLICY_MANAGER_DEFINED_EVENT),
        first()).subscribe(() => {
            resolve(window.Rai.PolicyManager);
        });
        window.setTimeout(() => {
            sub.unsubscribe();
            resolve(window.Rai.PolicyManager);
        }, 5000);
    });
}
