import { css, html, LitElement, unsafeCSS } from "lit-element";
import { getGDPRManager } from "./utilities";

// @ts-ignore
import styles from "./style.shadow.scss";
import _adoptedStyles from "./fonts.css";

export class Footer extends LitElement {

  static get styles() {
    return [
      css`${unsafeCSS(styles)}`,
    ];
  }

  static get properties() {
    return {
      mode: { type: String },
    };
  }

  constructor() {
    super();
    this.mode = 'dark';
    this.consentsStatus = undefined;
    this.getConsentsStatus = this.getConsentsStatus.bind(this);
    this.networkMenu = undefined;
    this.networkMenuOpened = false;
  }
  
  connectedCallback() {
    super.connectedCallback();
    this.getConsentsStatus();
    //this.fontfaceObserver();
    window.addEventListener('rai:opted_in', this.getConsentsStatus);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('rai:opted_in', this.getConsentsStatus);
  }

  firstUpdated(){
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap';
    link.rel = 'stylesheet';
    this.shadowRoot.appendChild(link);
    this.checkAutomotive();

  }

  checkAutomotive(){
    var result = false;
    var footer = document.querySelector("rai-footer");
    var prefooter = document.querySelectorAll(".prefooter");
    var REGEXs = [/bmw/i, /Faurecia Aptoide/i, /Harman Automotive/i];
    REGEXs.forEach(function (auto) {
      if (auto.test(navigator.userAgent)) result = true;
    });
    if (result) {
      footer.classList.add('hide');
      prefooter.forEach(function (el) {
        el.classList.add('hide');
      })
    }
  }

  async fontfaceObserver() {
    const sheet = new CSSStyleSheet();
    
    await this.updateComplete;
    const footer = document.querySelector('rai-footer');
    
    let options = {
      rootMargin: '0px 0px 600px 0px'
    }
    const myCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          sheet.replaceSync(_adoptedStyles);
          document.adoptedStyleSheets = [...document.adoptedStyleSheets, sheet];
          observer.disconnect();
        }
      })
    }
    const observer = new IntersectionObserver(myCallback, options);
    footer && observer.observe(footer);
  }

  async getConsentsStatus() {
    const status = await getGDPRManager().then(function (PolicyManager) {
      if (!PolicyManager.getPurposeConsents) return;
      const consentsObj = PolicyManager.getPurposeConsents(true);
      const totalConsents = Object.keys(consentsObj).length;
      let acceptedConsents = 0;

      for (let key in consentsObj) {
        if (consentsObj[key]) acceptedConsents++;
      };

      if (acceptedConsents === 0) return 'none'
      if (acceptedConsents === totalConsents) return 'all'

      return 'some';
    });
    this.consentsStatus = status;
    this.requestUpdate();
  }

  getTextStatus(contentsStatus) {
    switch (contentsStatus) {
      case 'none':
        return 'Tutti non accettati';
      case 'all':
        return 'Tutti accettati';
      default:
        return 'Parzialmente accettati';
    }
  }

  showPreferenceCenter(e) {
    e.stopPropagation();
    e.preventDefault();

    const cookieManager = window.AS_OIL;
    if (cookieManager) {
      let preferenceDiv = null;
      if (document.querySelector('#as-oil-cpc') == null) {
        /**
         * @type {MutationCallback}
         */
        const callback = (mutationList, observer) => {
          for (const mutation of mutationList) {
            if (mutation.type === 'childList' && mutation.addedNodes.length) {
              const target = document.querySelector('div.as-oil');
              if (target) {
                observer.disconnect();

                const back = target.querySelector('button.as-oil-back-button');
                if (back) {
                  back.remove();
                }

                const icon = document.createElement('img');
                icon.src = '//www.rai.it/dl/components/img/close.svg';
                icon.setAttribute('style', 'filter: invert(1);');

                const closeBut = document.createElement('button');
                closeBut.setAttribute('style', 'position:absolute;top:0.7em;right:0.7em;cursor:pointer;padding:0;appearance:none;border:0;border-radius:0;background:transparent;line-height:1');
                closeBut.appendChild(icon);
                target.appendChild(closeBut);

                closeBut.addEventListener('click', (e) => {
                  window.removeEventListener('message', getMessage);
                  target.remove();
                });

                const container = document.querySelector('#as-oil-cpc');
                container.appendChild(closeBut);
              }
            }
          }
        };
        const observer = new MutationObserver(callback);
        observer.observe(document.body, {
          childList: true
        });
      }

      function getMessage(message) {
        if (message.data === 'oil_optin_done_button_clicked') {
          window.removeEventListener('message', getMessage);
          if (preferenceDiv) {
            preferenceDiv.remove();
          }
        }
      }
      window.addEventListener('message', getMessage);
      cookieManager.showPreferenceCenter('absolute');
    }
  }

  getNetworkDomain() {
    const dev = 'rai-dev.rai.it';
    const pre = 'rai-pre.rai.it';
    if (location.host.includes('dev.rai.it')){
      return `https://${dev}`
    }
    if (location.host.includes('pre.rai.it')){
      return `https://${pre}`
    }
    return `https://www.rai.it`
  }

  async getNetworkMenu(){
    const domain = this.getNetworkDomain();
    const resp = await fetch(`${domain}/menu.json`);
    if (resp.ok){
      const data = await resp.json();
      this.networkMenu = this.networkMenuAdapter(data);
    }
  }

  networkMenuAdapter(data){
    return data.main_menu.navs.reduce((accumulator, row) => {
      row.navs.map((element) => accumulator.push(element.item));
      return accumulator;
    }, []);
  };

  async networkToggle(){
    if (!this.networkMenu){
      await this.getNetworkMenu()
      this.networkMenuOpened = true;
      await this.requestUpdate();
      return;
    }

    this.networkMenuOpened = !this.networkMenuOpened;
    await this.requestUpdate();
  }

  renderNetworkMenu(){
    if (!this.networkMenu) return '';
    return html`
      ${this.networkMenu.map((item) => {
        return html`
          <div class="footer-network-menu__item">
            <a href="${item.weblink}" target="_blank" title="${item.labelCTA}" class="footer-network-menu__item--title">${item.labelCTA}</a>
            <span class="footer-network-menu__item--description">${item.description}</span>
          </div>        
        `
      })}
      <div class="footer-network-menu__item footer-network-menu__item--gotoall">
          <a href="${`${this.getNetworkDomain()}/network`}" target="_blank" title="Vai all'elenco completo" class="footer-network-menu__item--title">
              Vai all'elenco completo
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v7a1 1 0 1 1-2 0V6.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L9.586 5H5a1 1 0 0 1-1-1z" fill="#687087"/>
              </svg>
          </a>          
      </div>
    `
  }

  render() {
    return html`
        <div part="wrapper" class="footer-wrapper ${this.mode}">
            <div class="footer-container">
              <div class="footer-network footer-network--menu-${this.networkMenuOpened && 'open' || 'closed'}">
                  <div class="footer-network__header">
                    <div class="footer-network__label">Network Rai</div>
                    <div class="footer-network__expand" @click="${this.networkToggle}">
                      <div class="footer-network__expand__arrow">
                        <span>${this.networkMenuOpened && 'Riduci' || 'Espandi'}</span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.522 8.522a1.1 1.1 0 0 1 1.556 0L12 14.444l5.922-5.922a1.1 1.1 0 1 1 1.556 1.556l-6.7 6.7a1.1 1.1 0 0 1-1.556 0l-6.7-6.7a1.1 1.1 0 0 1 0-1.556z" fill="#687087"/>
                        </svg>
                      </div>
                      <div class="footer-network__expand__operator">
                        ${this.networkMenuOpened && html`
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.9 12A1.1 1.1 0 0 1 5 10.9h14a1.1 1.1 0 0 1 0 2.2H5A1.1 1.1 0 0 1 3.9 12z" fill="#0060E6"/>
                            </svg>
                        ` || html`
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1 5a1.1 1.1 0 0 0-2.2 0v5.9H5a1.1 1.1 0 0 0 0 2.2h5.9V19a1.1 1.1 0 0 0 2.2 0v-5.9H19a1.1 1.1 0 0 0 0-2.2h-5.9V5z" fill="#687087"/>
                            </svg>
                        `}
                      </div>
                    </div>                    
                  </div>
                  <div class="footer-network-menu">
                    ${this.renderNetworkMenu()}
                  </div>
              </div>
              <div class="footer-grid">
                <nav class="footer-menu">
                  <a class="footer-menu__link" href="https://www.rai.it/trasparenza/">Rai Corporate</a>
                  <a class="footer-menu__link" href="https://www.rai.it/account/Privacy-attivita-giornalistica-c7cbb829-9bff-4d3a-ada8-c5d732526283.html">Privacy attività giornalistica</a>
                  <a class="footer-menu__link" href="https://www.rai.it/privacy/PrivacyPolicy.html">Privacy Policy</a>
                  <a class="footer-menu__link" href="https://www.rai.it/privacy/CookiePolicy.html">Cookie Policy</a>
                  <div class="footer-menu__group">
                    <button class="footer-menu__button" @click=${this.showPreferenceCenter}>
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#jvurehwbsa)">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M5 4.1a1.1 1.1 0 1 0 0-2.2 1.1 1.1 0 0 0 0 2.2zM7.723 4a2.901 2.901 0 0 1-5.446 0H1a1 1 0 0 1 0-2h1.277a2.901 2.901 0 0 1 5.446 0H15a1 1 0 1 1 0 2H7.723zM5 14.1a1.1 1.1 0 1 0 0-2.2 1.1 1.1 0 0 0 0 2.2zm2.723-.1a2.901 2.901 0 0 1-5.446 0H1a1 1 0 1 1 0-2h1.277a2.901 2.901 0 0 1 5.446 0H15a1 1 0 1 1 0 2H7.723zM12.1 8a1.1 1.1 0 1 1-2.2 0 1.1 1.1 0 0 1 2.2 0zM11 10.9c1.25 0 2.316-.791 2.723-1.9H15a1 1 0 1 0 0-2h-1.277a2.901 2.901 0 0 0-5.446 0H1a1 1 0 1 0 0 2h7.277A2.901 2.901 0 0 0 11 10.9z"/>
                          </g>
                          <defs>
                              <clipPath id="jvurehwbsa">
                                  <path fill="#fff" d="M0 0h16v16H0z"/>
                              </clipPath>
                          </defs>
                      </svg>
                      Preferenze Cookie
                    </button>
                    ${this.consentsStatus && html`<span class="footer-menu__legend">
                      <span class="footer-menu__legend__bullet footer-menu__legend__bullet--${this.consentsStatus}"></span>
                      ${this.getTextStatus(this.consentsStatus)}
                    </span>`}
                  </div>
                </nav>
                <svg class="footer-logo" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#w038y3q5za)"><path fill-rule="evenodd" clip-rule="evenodd" d="M40 0v40H0V0h40zM22.324 16.869c-3.163 0-5.347 2.617-5.347 5.671 0 3.054 2.226 5.692 5.388 5.692 1.175 0 2.405-.423 3.101-1.356l.124-.18h.042v1.183h3.786V17.222h-3.786v1.038c-.791-.934-2.08-1.391-3.308-1.391zm-11.637-4.654H4.59v15.664h4.078v-6.024h.042l3.849 6.024h5.076l-4.93-6.419c2.226-.395 3.432-2.265 3.432-4.425 0-3.345-2.35-4.82-5.45-4.82zm24.698 5.007h-3.787v10.657h3.787V17.222zm-12.062 2.929c1.54 0 2.433 1.1 2.433 2.41 0 1.35-.894 2.39-2.433 2.39-1.54 0-2.434-1.04-2.434-2.39 0-1.31.894-2.41 2.434-2.41zm-14.26-4.82c1.332 0 2.83.25 2.83 1.953 0 1.643-1.393 1.933-2.686 1.952h-.539v-3.905h.395zm24.429-4.32a2.13 2.13 0 0 0-2.123 2.118 2.13 2.13 0 0 0 2.123 2.119 2.129 2.129 0 0 0 2.121-2.119 2.13 2.13 0 0 0-2.121-2.119z"/></g>
                  <defs>
                    <clipPath id="w038y3q5za">
                      <path fill="#fff" d="M0 0h40v40H0z"/>
                    </clipPath>
                  </defs>
                </svg>
                <div class="footer-details">
                  <span>Rai - Radiotelevisione Italiana Spa</span>
                  <span>Sede legale: Viale Mazzini, 14 - 00195 Roma</span>
                  <span>Cap. Soc. Euro 242.518.100,00 interamente versato</span>
                  <span>Ufficio del Registro delle Imprese di Roma</span>
                  <span>© Rai ${new Date().getFullYear()} - tutti i diritti riservati. P.Iva 06382641006</span>
                </div>
              </div>
            </div>
        </div>
        `
  }
}